import './App.css';
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Body from "./components/Body/Body";

function App() {
  return (
    [
      <Header/>,
      <Menu/>,
      <Body/>
      ]
  );
}

export default App;

import React from 'react'
import epc from '../../images/epc.png'

class Header extends React.Component {
    constructor() {
        super();
        this.styleCenter = {
            backgroundColor: "blue",
            color: "white",
            fontSize: "50px",
            textAlign: "center",
            verticalAlign: "middle",
            linkColor: "white",
            vlinkColor: "white"
        };
        this.styleCenterSmall = {
            backgroundColor: "blue",
            color: "white",
            fontSize: "20px",
            textAlign: "center",
            verticalAlign: "middle",
            linkColor: "white",
            vlinkColor: "white"
        };
        this.styleLeft = {
            backgroundColor: "blue",
            color: "white",
            fontSize: "50px",
            textAlign: "left",
            verticalAlign: "middle",
            linkColor: "white",
            vlinkColor: "white"
        };
    }
    render() {
        return (
            <div style={this.styleLeft}>
                <table width={"100%"}><tr>
                <td><img src={epc} style={this.styleCenter}/></td>
                <td style={this.styleLeft}>Twin Tiers EPC</td>
                <td style={this.styleCenterSmall}>8 Canal St.<br/>Big Flats, NY<br/>(607) 546-9590</td> {/* add some spaces to add distance*/}
                </tr></table>
            </div>
        )
    }
}

export default Header;
import React from 'react'

class Menu extends React.Component {
    constructor() {
        super();
        this.styleCenter = {
            backgroundColor: "steelBlue",
            color: "white",
            fontSize: "30px",
            textAlign: "center",
            verticalAlign: "middle",
            linkColor: "white",
            vlinkColor: "white"
        };
    }

    render() {
        return (
            <div style={this.styleCenter}>
                <table width={"100%"}>
                    <tr>
                        <td style={this.styleCenter}><a style={{color:"white"}} href="home">Home</a></td>
                        <td style={this.styleCenter}><a style={{color:"white"}} href="calendar">Weekly Calendar</a></td>
                        <td style={this.styleCenter}><a style={{color:"white"}} href="https://www.youtube.com/channel/UCMRbFvYgacJFxJ1atoBqt8w/">Videos (YouTube)</a></td>
                        <td style={this.styleCenter}><a style={{color:"white"}} href="belief">What We Believe</a></td>
                    </tr>
                </table>
            </div>
        )
    }
}

export default Menu;
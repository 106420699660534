import React from 'react'
import { Fade } from 'react-slideshow-image';
import slide001 from '../../images/banner.jpg'
import slide002 from '../../images/slide002.jpg'
import slide003 from '../../images/slide003.jpg'
import slide004 from '../../images/slide004.jpg'
import slide005 from '../../images/slide005.jpg'
import slide006 from '../../images/slide006.jpg'
import slide007 from '../../images/slide007.jpg'
import slide008 from '../../images/slide008.jpg'
import slide009 from '../../images/slide009.jpg'
import slide010 from '../../images/slide010.jpg'
import slide011 from '../../images/slide011.jpg'
import 'react-slideshow-image/dist/styles.css'

const fadeImages = [
    slide001,
    slide002,
    slide003,
    slide004,
    slide005,
    slide006,
    slide007,
    slide008,
    slide009,
    slide010,
    slide011
];

const fadeOutProperties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.25,
    arrows: true
};


class Home extends React.Component {
    constructor() {
        super();
        this.styleCenter = {
            backgroundColor: "lightSteelBlue",
            color: "black",
            fontSize: "50px",
            textAlign: "center",
            verticalAlign: "middle"
        };
    }
    render() {
        return (
            <div className="slide-container" style={this.styleCenter}>
                <Fade {...fadeOutProperties}>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[0]} alt="" height="600px"/>
                        </div>
                        Welcome!
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[1]}  alt="" height="600px"/>
                        </div>
                        Join us Sundays at 9:30am for Worship
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[2]}  alt="" height="600px"/>
                        </div>
                        Join us for Weekly Bible Studies
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[3]}  alt="" height="600px"/>
                        </div>
                        We are a community that cares and prays for each other
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[4]}  alt="" height="600px"/>
                        </div>
                        We believe that Jesus died and rose for our salvation
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[5]}  alt="" height="600px"/>
                        </div>
                        We believe that the Bible is the inspired Word of God
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[6]}  alt="" height="600px"/>
                        </div>
                        We gather together to give thanks and worship
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[7]}  alt="" height="600px"/>
                        </div>
                        We make a joyful noise unto the Lord
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[8]}  alt="" height="600px"/>
                        </div>
                        We look forward to Jesus returning
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[9]}  alt="" height="600px"/>
                        </div>
                        Families are welcome
                    </div>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={fadeImages[10]}  alt="" height="600px"/>
                        </div>
                        Thank you Jesus for your work on the cross
                    </div>
                </Fade>
            </div>        )
    }
}

export default Home;
import React from 'react'
import {history} from "../../store/History";
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from "../../store";
import Home from "../Home/Home";
import Belief from "../Belief/Belief";
import Calendar from "../Calendar/Calendar";

class Body extends React.Component {
    constructor() {
        super();
        this.styleCenter = {
            backgroundColor: "lightSteelBlue",
            color: "white",
            fontSize: "50px",
            textAlign: "center",
            verticalAlign: "middle"
        };
    }
    render() {
        return (
            <Router history={history}>
                <Provider store={store}>
                    <div style={this.styleCenter}>
                        <Route
                            exact
                            path={"/"}
                            render={()=>(<Home/>)}
                        />
                        <Route
                            exact
                            path={"/home"}
                            render={()=>(<Home/>)}
                        />
                        <Route
                            exact
                            path={"/calendar"}
                            render={()=>(<Calendar/>)}
                        />
                        <Route
                            exact
                            path={"/belief"}
                            render={()=>(<Belief/>)}
                        />
                    </div>
                </Provider>
            </Router>
        )
    }
}

export default Body;
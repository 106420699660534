import React from "react";
import background from "../../images/backround.png"

class Calendar extends React.Component {
    constructor() {
        super();
        this.styleLeft = {
            backgroundColor: "lightSteelBlue",
            color: "black",
            fontSize: "30px",
            textAlign: "left",
            verticalAlign: "middle",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover"
        };
    }
    render() {
        return (
            <div style={this.styleLeft}>
                <br/>
                <div align="center">Sunday Worship</div>
                <br/>
                <table align="center" width="60%">
                    <tr>
                        <td>
                            Join us online or in person for Sunday morning worship at 9:30am.  The worship service is streamed to our Facebook page as well as to our YouTube channel.
                        </td>
                    </tr>
                </table>
                <br/><br/>
                <div align="center">Sunday School</div>
                <br/>
                    <table align="center" width="60%">
                        <tr>
                            <td>
                                Join us for in person Sunday School immediately following the worship service.
                            </td>
                        </tr>
                    </table>
                <br/><br/>
                <div align="center">Men's Bible Study</div>
                <br/>
                <table align="center" width="60%">
                    <tr>
                        <td>
                            All men are welcome to join us for in person Bible study and prayer every Thursday evening at 7:00pm.
                        </td>
                    </tr>
                </table>
                <br/><br/>
                <div align="center">Women's Bible Study</div>
                <br/>
                <table align="center" width="60%">
                    <tr>
                        <td>
                            All women are welcome to join us for online (WebEx) Bible study and prayer every Thursday evening at 7:00pm.
                        </td>
                    </tr>
                </table>
                <br/><br/><br/>
            </div>
        )
    }
}

export default Calendar;

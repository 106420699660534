import React from 'react'
import background from "../../images/backround.jpg"

class Belief extends React.Component {
    constructor() {
        super();
        this.styleLeft = {
            backgroundColor: "lightSteelBlue",
            color: "black",
            fontSize: "30px",
            textAlign: "left",
            verticalAlign: "middle",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover"
        };
    }
    render() {
        return (
            <div style={this.styleLeft}>
                <br/>
                <div align="center">The Essentials</div>
                <table align="center" width="60%"><tr><td>
                    All	Scripture	is	self-attesting, and	being	Truth requires	our	unreserved	submission	in	all
                    areas of	life.	The	infallible	Word	of	God—the	66	books	of	the	Old	and	New	Testaments—is
                    a	complete	and	unified	witness	to	God’s redemptive	acts culminating	in	the	incarnation	of
                    the	Living	Word,	the	Lord	Jesus	Christ.	The Bible,	uniquely	and	fully	inspired	by	the	Holy
                    Spirit,	is	the	supreme	and	final	authority	on	all matters	on	which	it	speaks.	On	this	sure
                    foundation, we	affirm	these	additional	essentials of	our	faith.<br/><br/>
                    1. We	believe	in	one	God,	the	sovereign	Creator	and	Sustainer	of	all	things, infinitely
                    perfect	and	eternally	existing	in three Persons:	Father,	Son,	and	Holy	Spirit.	To	Him
                    be	all	honor,	glory, and praise	forever!<br/><br/>
                    2. Jesus	Christ,	the	living	Word,	became	flesh	through	His	miraculous	conception	by
                    the	Holy	Spirit	and	His	virgin	birth.	He	who	is	true	God	became	true	man	united	in
                    one	Person	forever.	He	died	on	the	cross	a	sacrifice	for	our	sins	according	to	the
                    Scriptures.	On	the	third	day	He	arose	bodily	from	the	dead,	ascended	into	heaven
                    where,	at	the	right	hand	of	the	Majesty	on	High,	He	now	is	our	High Priest	and
                    Mediator.<br/><br/>
                    3. The	Holy	Spirit	has	come	to	glorify	Christ	and	to	apply	the	saving	work	of	Christ	to
                    our	hearts.	He	convicts	us	of	sin	and	draws	us	to	the	Savior,	indwelling	our	hearts.
                    He	gives	new	life	to	us,	empowers	and	imparts	gifts	to	us	for	service.	He	instructs
                    4. Being	estranged	from	God	and	condemned	by	our	sinfulness,	our	salvation	is	wholly
                    dependent	upon	the	work	of	God’s	free	grace.	God	credits	His	righteousness	to	those
                    who	put	their	faith	in	Christ	alone	for	their	salvation,	and	thereby	justifies	them	in
                    His	sight.	Only	such	as	are	born	of	the	Holy	Spirit	and	receive	Jesus	Christ	become
                    children	of	God	and	heirs	of	eternal	life.<br/><br/>
                    5. The	true	Church	is	composed	of	all	persons	who	through	saving	faith	in	Jesus	Christ
                    and	the	sanctifying	work	of	the	Holy	Spirit	are	united	together	in	the	body	of	Christ.
                    The Church	finds	her	visible	yet	imperfect	expression	in	local	congregations	where
                    the	Word	of	God	is	preached	in	its	purity	and	the	sacraments	are	administered	in
                    their	integrity,	where	scriptural	discipline	is	practiced,	and	where	loving	fellowship
                    is	maintained.	For	her	perfecting	she	awaits	the	return	of	her	Lord.<br/><br/>
                    6. Jesus	Christ	will	come	again	to	the	earth	personally,	visibly,	and	bodily—to	judge	the
                    living	and	the	dead,	and	to	consummate	history	and	the	eternal	plan	of	God.	“Even
                    so,	come,	Lord	Jesus”	(Revelation	22:20).<br/><br/>
                    7. The	Lord	Jesus	Christ	commands	all	believers	to	proclaim	the	gospel	throughout	the
                    world	and	to	make	disciples	of	all	nations.	Obedience	to	the	Great	Commission
                    requires	total	commitment	to	“Him	who	loved	us	and	gave	Himself	for	us.”	He	calls
                    us	to	a	life	of	self-denying	love	and	service.	“For	we	are	His	workmanship,	created	in
                    Christ	Jesus	for	good	works,	which	God	prepared	beforehand	that	we	should	walk	in
                    <br/><br/>
                    The	Essentials	are set	forth	in	greater	detail	in	the	Westminster	Confession	of	Faith.<br/><br/>
                    In	Essentials	…	Unity<br/>
                    In	Non-Essentials	…	Liberty<br/>
                    In	All	Things	…	Charity
                </td></tr></table>
                <br/><br/><br/>
            </div>
        )
    }
}

export default Belief;
